<template>
  <div class="view-contact-us">
    <div class="header-img">
      <img class="w100" src="@/assets/images/all/pc-icon6.png" alt="" srcset="">
    </div>
    <div class="center-content">
      <div class="title flex-c">
        <div class="text">Contact Us</div>
      </div>
      <div class="main">
        <div class="left">
          <div class="title">
            Chengdu Orecto Import and Export Trading Co., Ltd
          </div>
          <div class="line"></div>
          <div v-for="(item,index) in compInfoList" :key="index" class="item">
            <div class="left-icon">
              <img class="w100" :src="item.img" alt="" srcset="">
            </div>
            <div class="right-content">
              <div class="r-title">{{item.title}}</div>
              <div class="r-text">
                {{item.content}}
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">
            Online Message
          </div>
          <div class="line"></div>
          <div class="input-box flex-s">
            <el-input v-model="formData.name" placeholder="Name"></el-input>
          </div>
          <div class="input-box flex-s">
            <el-input v-model="formData.mobile" placeholder="Mobile"></el-input>
          </div>
          <div class="input-box flex-s">
            <el-input v-model="formData.email" placeholder="E-mail"></el-input>
          </div>
          <div class="textarea-box">
            <el-input type="textarea" placeholder="Question or inquiry" v-model="formData.content">
            </el-input>
          </div>
          <div class="btn-box flex-c">
            <div @click="submitForm" class="btn flex-c point">Submit</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { contactUsApi } from '@/network/api';
export default {
  data() {
    return {
      compInfoList: [
        {
          img: require('@/assets/images/all/pc-icon701.png'),
          title: 'Address',
          content: 'No. 666, Guandong 1st Street, Chengdu High-tech Zone, China (Sichuan) Free Trade Pilot Zone'
        },
        {
          img: require('@/assets/images/all/pc-icon702.png'),
          title: 'Website',
          content: 'www.orectofloor.com'
        },
        {
          img: require('@/assets/images/all/pc-icon703.png'),
          title: 'Phone',
          // content: '+86 13608202562'
          content: ''
        },
        {
          img: require('@/assets/images/all/pc-icon704.png'),
          title: 'E-mail',
          // content: 'orecto@orectofloor.com'
          content: ''
        },
      ],
      formData: {
        name: '',
        mobile: '',
        email: '',
        content: ''
      }
    };
  },
  methods: {
    async submitForm() {
      if (!this.formData.mobile) {
        this.$message.error('mobile not empty');
        return false;
      }
      const res = await contactUsApi(this.formData);
      this.$message.success('success');
      this.formData = this.$options.data().formData;
    }
  }
}
</script>

<style lang="scss">
.view-contact-us {
  .header-img {
    margin-bottom: 0.6rem;
  }
  .center-content {
    .title {
      font-size: 0.5rem;
      margin-bottom: 0.6rem;
      .text {
        position: relative;
        font-family: Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 800;
      }
      .text:after {
        content: "";
        width: 0.4rem;
        height: 0.15rem;
        position: absolute;
        top: 0.05rem;
        left: -0.15rem;
        background: rgba(161, 179, 173, 0.5);
      }
    }
    .main {
      padding: 0 3.1rem;
      height: 6.21rem;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-bottom: 1.5rem;
      .left {
        width: 6.5rem;
        height: 100%;
        padding: 0.6rem;
        box-sizing: border-box;
        flex-shrink: 0;
        background: rgba(70, 106, 93, 0.1);
        .title {
          font-size: 0.24rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          margin-bottom: 0.14rem;
          width: 4.75rem;
        }
        .line {
          width: 0.5rem;
          height: 0.04rem;
          background: rgba(142, 170, 142, 0.5);
          margin-bottom: 0.4rem;
        }
        .item {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          margin-bottom: 0.23rem;
          .left-icon {
            width: 0.6rem;
            height: 0.6rem;
            margin-right: 0.2rem;
            flex-shrink: 0;
          }
          .right-content {
            font-size: 0.2rem;
            .r-title {
              font-weight: bold;
              margin-bottom: 0.02rem;
            }
            .r-text {
              font-size: 0.18rem;
              line-height: 0.21rem;
            }
          }
        }
        .item:last-of-type {
          margin-bottom: 0;
        }
      }
      .right {
        width: 6.5rem;
        height: 100%;
        padding: 0.6rem;
        flex-shrink: 0;
        box-sizing: border-box;
        background: rgba(221, 226, 221, 0.2);
        .title {
          font-size: 0.24rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          margin-bottom: 0.14rem;
        }
        .line {
          width: 0.5rem;
          height: 0.04rem;
          background: rgba(142, 170, 142, 0.5);
          margin-bottom: 0.74rem;
        }
        .input-box {
          width: 100%;
          height: 0.4rem;
          background: rgba(238, 241, 236, 1);
          margin-bottom: 0.26rem;
          padding: 0 0.16rem;
          box-sizing: border-box;
          font-size: 0.2rem;
        }
        .textarea-box {
          width: 100%;
          height: 1.41rem;
          background: rgba(238, 241, 236, 1);
          margin-bottom: 0.25rem;
          padding: 0.06rem 0.16rem;
          box-sizing: border-box;
          font-size: 0.2rem;
        }
        .btn-box {
          .btn {
            padding: 0.06rem 0.68rem;
            box-sizing: border-box;
            background: rgba(0, 94, 60, 1);
            color: #fff;
            border-radius: 0.02rem;
          }
        }
      }
    }
  }
}
</style>